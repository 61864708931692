<template>
  <div>
    <div>
      <!-- created -->
      <div>
        <template
          v-if="
            institution &&
            (institution.internal_use_id == 'ciisa_uss' ||
              institution.show_competence_unit)
          "
        >
          <ButtonSmall
            v-if="allows_crud"
            :tooltip_text="`Agregar ${$getVisibleNames(
              'teaching.tempcompetenceunit',
              false,
              'Unidad de competencia'
            )}`"
            :click_button="openModalCreateCompetenceUnit"
            :icon="'plus'"
            :variant="'none'"
          >
          </ButtonSmall>
          <!-- <button-add
            v-if="allows_crud"
            @click="openModalCreateCompetenceUnit()"
            v-b-tooltip.v-secondary.noninteractive="
              `Agregar ${$getVisibleNames(
                'teaching.tempcompetenceunit',
                false,
                'Unidad de competencia'
              )}`
            "
          >
          </button-add> -->
          <b-modal
            :id="`add-unit-competence-modal-${matter_id}`"
            hide-footer
            :title="`Agregar ${$getVisibleNames(
              'teaching.tempcompetenceunit',
              false,
              'Unidad de competencia'
            )}`"
            size="lg"
            ignore-enforce-focus-selector="#sidebar-right"
            @hide="hideModalCompetenceUnit"
          >
            <CompetenceUnitForm
              :matter_id="matter_id"
              @created="$bvModal.hide(`add-unit-competence-modal-${matter_id}`)"
              @close_modal="
                $bvModal.hide(`add-unit-competence-modal-${matter_id}`)
              "
            ></CompetenceUnitForm>
          </b-modal>
        </template>
      </div>
      <div
        v-for="(competence_unit, index) in competenceUnits"
        :key="'CompetenceUnitCell' + competence_unit.id + '-' + matter_id"
        :class="{ competence_cell_block: index + 1 != competenceUnits.length }"
      >
        <div>
          <label v-if="competence_unit.name" class="competence-unit-label">{{
            competence_unit.name
          }}</label>
          <div
            class="d-flex"
            v-if="competence_unit && competence_unit.full_sentence.trim() != ''"
          >
            <SentenceContainer :Sentence="competence_unit">
              <template #order>
                <strong> {{ competence_unit.order }}. </strong>
              </template>
            </SentenceContainer>
          </div>
          <div>
            <ButtonSmall
              v-if="allows_crud"
              :tooltip_text="`Editar ${$getVisibleNames(
                'teaching.tempcompetenceunit',
                false,
                'Unidad de competencia'
              )}`"
              :click_button="
                () => openModalEditCompetenceUnit(competence_unit.id)
              "
              :icon="'square'"
            ></ButtonSmall>
            <!-- <button-edit
              v-if="allows_crud"
              @click="openModalEditCompetenceUnit(competence_unit.id)"
              v-b-tooltip.v-secondary.noninteractive.top="
                `Editar ${$getVisibleNames(
                  'teaching.tempcompetenceunit',
                  false,
                  'Unidad de competencia'
                )}`
              "
            ></button-edit> -->
            <b-modal
              :id="`edit-unit-competence-modal-${competence_unit.id}-${matter_id}`"
              hide-footer
              :title="`Editar ${$getVisibleNames(
                'teaching.tempcompetenceunit',
                false,
                'Unidad de competencia'
              )}`"
              size="lg"
              ignore-enforce-focus-selector="#sidebar-right"
              @hide="hideModalCompetenceUnit"
            >
              <CompetenceUnitForm
                :matter_id="matter_id"
                :CompetenceUnit="competence_unit"
                :show_delete_button="true"
                @created="
                  $bvModal.hide(
                    `edit-unit-competence-modal-${competence_unit.id}-${matter_id}`
                  )
                "
                @updated="
                  $bvModal.hide(
                    `edit-unit-competence-modal-${competence_unit.id}-${matter_id}`
                  )
                "
              ></CompetenceUnitForm>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CompetenceUnitCell",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    CompetenceUnitForm: () =>
      import(
        "@/components/teaching/MatterProgram/Components/CompetenceUnitForm"
      ),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    profileMatter: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      competence_units: names.COMPETENCE_UNITS,
    }),
    competenceUnits() {
      return this.competence_units.filter((x) => x.matter == this.matter_id);
    },
  },
  methods: {
    openModalCreateCompetenceUnit() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.profileMatter) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.profileMatter.egress_profile,
            egress_profile_matter_id: this.profileMatter.id,
            intelligence_option: 4,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [4],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
          });
        });
      }
      this.$bvModal.show(`add-unit-competence-modal-${this.matter_id}`);
    },
    openModalEditCompetenceUnit(competence_unit_id) {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.profileMatter) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.profileMatter.egress_profile,
            egress_profile_matter_id: this.profileMatter.id,
            intelligence_option: 4,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [4],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: true,
          });
        });
      }
      this.$bvModal.show(
        `edit-unit-competence-modal-${competence_unit_id}-${this.matter_id}`
      );
    },

    hideModalCompetenceUnit() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      if (this.profileMatter) {
        this.$nextTick(() => {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.profileMatter.egress_profile,
            egress_profile_matter_id: null,
            competence_unit_id: null,
            intelligence_option: 2,
          });
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [2, 4, 5, 7],
            disabled_selected_egress_profile: true,
            disabled_selected_egress_profile_matter: null,
            disabled_selected_study_unit: null,
            disabled_selected_competence_unit: null,
          });
        });
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.competence_cell_block {
  border-bottom: 0.1px solid var(--secondary-color) !important;
}
.competence-unit-label {
  font-size: var(--secondary-font-size);
  margin-bottom: 0.3em;
  display: block;
  text-align: left;
  margin-left: 0.5em;
  font-weight: bold;
}
</style>


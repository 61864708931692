var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',[(
          _vm.institution &&
          (_vm.institution.internal_use_id == 'ciisa_uss' ||
            _vm.institution.show_competence_unit)
        )?[(_vm.allows_crud)?_c('ButtonSmall',{attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'teaching.tempcompetenceunit',
            false,
            'Unidad de competencia'
          )}`,"click_button":_vm.openModalCreateCompetenceUnit,"icon":'plus',"variant":'none'}}):_vm._e(),_c('b-modal',{attrs:{"id":`add-unit-competence-modal-${_vm.matter_id}`,"hide-footer":"","title":`Agregar ${_vm.$getVisibleNames(
            'teaching.tempcompetenceunit',
            false,
            'Unidad de competencia'
          )}`,"size":"lg","ignore-enforce-focus-selector":"#sidebar-right"},on:{"hide":_vm.hideModalCompetenceUnit}},[_c('CompetenceUnitForm',{attrs:{"matter_id":_vm.matter_id},on:{"created":function($event){return _vm.$bvModal.hide(`add-unit-competence-modal-${_vm.matter_id}`)},"close_modal":function($event){return _vm.$bvModal.hide(`add-unit-competence-modal-${_vm.matter_id}`)}}})],1)]:_vm._e()],2),_vm._l((_vm.competenceUnits),function(competence_unit,index){return _c('div',{key:'CompetenceUnitCell' + competence_unit.id + '-' + _vm.matter_id,class:{ competence_cell_block: index + 1 != _vm.competenceUnits.length }},[_c('div',[(competence_unit.name)?_c('label',{staticClass:"competence-unit-label"},[_vm._v(_vm._s(competence_unit.name))]):_vm._e(),(competence_unit && competence_unit.full_sentence.trim() != '')?_c('div',{staticClass:"d-flex"},[_c('SentenceContainer',{attrs:{"Sentence":competence_unit},scopedSlots:_vm._u([{key:"order",fn:function(){return [_c('strong',[_vm._v(" "+_vm._s(competence_unit.order)+". ")])]},proxy:true}],null,true)})],1):_vm._e(),_c('div',[(_vm.allows_crud)?_c('ButtonSmall',{attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
              'teaching.tempcompetenceunit',
              false,
              'Unidad de competencia'
            )}`,"click_button":() => _vm.openModalEditCompetenceUnit(competence_unit.id),"icon":'square'}}):_vm._e(),_c('b-modal',{attrs:{"id":`edit-unit-competence-modal-${competence_unit.id}-${_vm.matter_id}`,"hide-footer":"","title":`Editar ${_vm.$getVisibleNames(
              'teaching.tempcompetenceunit',
              false,
              'Unidad de competencia'
            )}`,"size":"lg","ignore-enforce-focus-selector":"#sidebar-right"},on:{"hide":_vm.hideModalCompetenceUnit}},[_c('CompetenceUnitForm',{attrs:{"matter_id":_vm.matter_id,"CompetenceUnit":competence_unit,"show_delete_button":true},on:{"created":function($event){return _vm.$bvModal.hide(
                  `edit-unit-competence-modal-${competence_unit.id}-${_vm.matter_id}`
                )},"updated":function($event){return _vm.$bvModal.hide(
                  `edit-unit-competence-modal-${competence_unit.id}-${_vm.matter_id}`
                )}}})],1)],1)])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }